/** @jsx h **/

import { component, h, When } from "alfama";
import { ShellProps } from "../../@types";
import { HydraKeyMap, HydraKeyMapType, IHydraState } from "@gratico/sdk";
import { getLastGroupId } from "../../utils/hydra";

export const filterKeyMap = (keyMap: HydraKeyMap[], hydra: IHydraState) => {
  // Determine the last active group based on the current active keys
  const lastGroupId = getLastGroupId(hydra.keyMap);
  return keyMap.filter((entry) => {
    return entry.parentId === lastGroupId;
    return false; // Exclude other entries
  });
};

export const HydraInner = component<ShellProps & { keyMap: HydraKeyMap[] }>(
  "Hydra",
  (props, { onMount, onUnmount }) => {
    const { $hydra } = props;
    const hydra = $hydra.get();
    const childGroups = filterKeyMap(props.keyMap, hydra);
    const last = hydra.keyMap[hydra.keyMap.length - 1];
    return (
      <div class="  px-4 font-sans w-full flex flex-col">
        <div class="grid grid-cols-3 gap-x-8 gap-y-1 flex-1 items-start py-2 ">
          {childGroups.map((cmd, index) => (
            <CommandItem key={index} cmd={cmd} />
          ))}
        </div>
        <div class="border-t">
          <div class="breadcrumbs text-sm">
            <ul>
              {hydra.keyMap.map((el, i) => {
                return (
                  <li key={i}>
                    <a>
                      {el.key}
                      {i === hydra.keyMap.length - 1 && (
                        <span class="px-1 hidden">({el.name})</span>
                      )}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
);

export const Hydra = component<ShellProps & { keyMap: HydraKeyMap[] }>(
  "Hydra",
  (props, { onMount, onUnmount }) => {
    const views = new Proxy(
      {},
      {
        get(target, p, receiver) {
          return () => <HydraInner {...props} />;
        },
      }
    );
    return (
      <When
        condition={($) => {
          const hydra = props.$hydra.get($);
          return Date.now();
        }}
        views={views}
      />
    );
  }
);

const CommandItem = (props: { cmd: HydraKeyMap }) => {
  const isGroup = props.cmd.type === HydraKeyMapType.GROUP;
  return (
    <div class="flex justify-start items-center mb-1 font-mono">
      <span
        style={`color: ${isGroup ? "violet" : "cornflowerblue"};`}
        class={`font-mono  mr-2`}
      >
        [{props.cmd.key}]:{" "}
      </span>
      <span class="text-gray-300 text-sm">
        {isGroup ? `+${props.cmd.name}` : props.cmd.name}
      </span>
    </div>
  );
};
